import { Injectable } from '@angular/core';
import * as firebase from 'firebase';
import { AngularFirestore } from '@angular/fire/firestore';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { AngularFirestoreCollection } from '@angular/fire/firestore';
import { ToastController } from '@ionic/angular';

@Injectable()
export class FirestoreService{
    

constructor(public firestore: AngularFirestore,
  private toastCtrl:ToastController) {}




createQuery(
  IDNumber:string,
    Name:string,
    FatherName:string,
    Sex:string,
    Address:string,
    WhatsappNo:string,
    RationCardNo:string,
    City:string,
    AadhaarNo:string,
    RefPerson:string,
    Department:string,
    JobAssignedTo:string,
    Status:string,
    Remarks:string,
    DoorNo:string,
    Ward:string,
    DocumentName:string,
    AgentRemarks:string,
    docUrl : string


  ) {


    const id = this.firestore.createId();
   
  return this.firestore.doc(`complaints/${id}`).set({
    id,
    IDNumber,
   Name,
    FatherName,
      Sex,
      Address,
      WhatsappNo,
      RationCardNo,
      City,
      AadhaarNo,
      RefPerson,
      Department,
      JobAssignedTo,
      Status,
      Remarks,
      DoorNo,
      Ward,
      DocumentName,
      AgentRemarks,
      docUrl
    
  });
  }



  updateQuery(
    IDNumber:string,
    Name:string,
    FatherName:string,
    Sex:string,
    Address:string,
    WhatsappNo:string,
    RationCardNo:string,
    City:string,
    AadhaarNo:string,
    RefPerson:string,
    Department:string,
    JobAssignedTo:string,
    Status:string,
    Remarks:string,
    DoorNo:string,
    Ward:string,
    DocumentName:string,
    AgentRemarks:string,
    id:string,
    docUrl: string

  ) {


   console.log('rem',AgentRemarks)
  return this.firestore.doc(`complaints/${id}`).set({
    IDNumber,Name,
    FatherName,
      Sex,
      Address,
      WhatsappNo,
      RationCardNo,
      City,
      AadhaarNo,
      RefPerson,
      Department,
      JobAssignedTo,
      Status,
      Remarks,
      DoorNo,
      Ward,
      DocumentName,
      AgentRemarks,
    id,
  docUrl  });
  }

  

createUser(
   
  userName:string,
  userRole:string,
  mobileNumber:string,
  password:string,


) {


  const id = this.firestore.createId();
 
return this.firestore.doc(`users/${id}`).set({
 userName,
userRole,
    mobileNumber,
    password,

  
});
}

updateUser(
  userName: string,
  userRole: string,
  mobileNumber: string,

  password: string,
  Id
) {
  return this.firestore.doc(`users/${Id}`).set({
    Id,
    userName,
    userRole,
    password,
    mobileNumber,
  });
}

  getUserList() {
      
    return this.firestore.collection(`users`);
  }

  ShowToastMessage(toastMessage){
    const toast = this.toastCtrl.create({
      message:toastMessage,
      duration:3000,
      position:"bottom",
    }).then(toast =>toast.present)
  }
}